import styled from 'styled-components'

export const Navbar = styled.nav`
  z-index: 3;
  position: fixed;
  user-select: none;
  width: 100%;

  font-size: 0;
  line-height: 0;
  a > div {
    float: right;
    margin-right: 5%;
    margin-top: 16px;
  }
  img {
    margin-bottom: 0;
  }
`
