import React from 'react'
import { Link } from 'gatsby'

import { Navbar } from './styles'
import Hover from '../Hover'

import closeIcon from '../../assets/icons/close_icon.svg'
import closeIconHover from '../../assets/icons/close_icon_hover.svg'

export default ({ link }) => (
  <Navbar>
    <Link to={link}>
      <Hover onHover={<img src={closeIconHover} />}>
        <img src={closeIcon} />
      </Hover>
    </Link>
  </Navbar>
)
