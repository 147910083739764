import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import Header from '../components/Header'
import { HTMLContent } from '../components/Content'
import { Container } from '../components/Content/styles'

export const ImprintPageTemplate = ({ title, content }) => (
  <HTMLContent content={content} />
)

const ImprintPage = ({ data }) => {
  const { markdownRemark } = data
  return (
    <Layout pageTitle={markdownRemark.frontmatter.title}>
      <Header link='/' />
      <Container>
        <ImprintPageTemplate content={markdownRemark.html} />
      </Container>
    </Layout>
  )
}

export default ImprintPage

export const ImprintPageQuery = graphql`
  query ImprintPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
  }
`
